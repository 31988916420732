if ( window.location.hash ) scroll(0,0)
setTimeout( function() { scroll(0,0) }, 1)

function hashScroll() {
	if(window.location.hash) {
		hash = window.location.hash
		scrollToHash(hash)
	}

	document.querySelectorAll('a[href^="#"]:not([href="#"]').forEach( trigger => {
		trigger.onclick = function(e) {
			e.preventDefault()
			if(!this.classList.contains('no-scroll')) {
				hash = this.getAttribute('href')
				scrollToHash(hash)
			}
		}
	})
}

function scrollToHash(hash) {
	let fixedNavHeight = document.querySelector('.fixed').offsetHeight,
		wpAdminBarHeight = document.querySelector('#wpadminbar') ? document.querySelector('#wpadminbar').offsetHeight : 0
		scrollOffset = 50,
		headerOffset = fixedNavHeight + scrollOffset + wpAdminBarHeight,
		target = document.querySelector(hash),
		targetPosition = target.offsetTop,
		offsetPosition = targetPosition - headerOffset

	window.scrollTo({
		top: offsetPosition,
		behavior: "smooth"
	})
}

window.addEventListener("load", hashScroll, false)