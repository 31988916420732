jQuery('.slider').slick({
	infinite: true,
	slidesToShow: 1,
	slidesToScroll: 1,
	dots:true,
	arrows : false,
	autoplay : true,
	autoplaySpeed: 6000,
	pauseOnHover: false,
	pauseOnFocus: false,
});
